/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Copied from Bootstrap utilities */
.d-flex {
    display: flex!important;
}

.d-inline-flex {
    display: inline-flex!important;
}

.flex-row {
    flex-direction: row!important;
}

.flex-row-reverse {
    flex-direction: row-reverse!important;
}

.flex-column {
    flex-direction: column!important;
}

.flex-column-reverse {
    flex-direction: column-reverse!important;
}

.flex-wrap {
    flex-wrap: wrap!important;
}

.align-items-end {
    align-items: flex-end!important;
}

.flex-grow-1 {
    flex-grow: 1!important;
}

.flex-grow-2 {
    flex-grow: 2!important;
}

.px-0 {
    padding-left: 0!important;
    padding-right: 0!important;
}

.px-1 {
    padding-left: 0.25rem!important;
    padding-right: 0.25rem!important;
}

.px-2 {
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
}

.px-3 {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
}
